import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Crowdfunding App Template | Crowdfunding App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/crowdfunding-mobile-app/"
    metaDescription="Bring your crowdfunding app design to life with our app design template for mobile devices. Sign up now and bring your idea to life."
    description="
    h2:Introducing our brand-new mobile crowdfunding app design template
    <br/>
    Working on your own crowdfunding app design? Our new <a:https://uizard.io/templates/mobile-app-templates/>app UI template</a>, is here to make your job all the easier! This UI mockup comes with all screens you could possibly need to design an app that stands the test of time. Streamline your design project today with Uizard templates, the fastest way to create a workable prototype.
    <br/>
    h3:Our templates are here to give you some time back in the design process
    <br/>
    Why waste time with complicated <a:https://uizard.io/ux-design/>UX design tools</a>? Uizard saves valuable time when it comes to turning your app ideas into design reality. Whether you are wireframing an early idea, adding clickable links to a mockup, or adapting a template, Uizard supports you to complete your project in minutes, not days.
    <br/>
    h3:Support a great cause... collaborative design
    <br/>
    Working with a team on your mobile crowdfunding app design? Collaboration on your app idea is super easy with Uizard’s smart collaboration features. Invite colleagues to view your prototype or add them to your project so you can edit your designs together.
    "
    pages={[
      "A smart and clean landing page with quirky lime green accents",
      "Campaign landing screen showcasing the cause of the week",
      "Crowdfunding campaign screen with further details and fundraising CTA",
      "User contribution screen with donation total and 'Pay now' CTA",
    ]}
    projectCode="XX09B4R141hGnbg0GlXW"
    img1={data.image1.childImageSharp}
    img1alt="crowdfunding mobile app design overview"
    img2={data.image2.childImageSharp}
    img2alt="crowdfunding mobile app design homescreen"
    img3={data.image3.childImageSharp}
    img3alt="crowdfunding mobile app design setup screen"
    img4={data.image4.childImageSharp}
    img4alt="crowdfunding mobile app design campaign screen"
    img5={data.image5.childImageSharp}
    img5alt="crowdfunding mobile app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/crowdfunding-mobile-app/crowdfunding-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/crowdfunding-mobile-app/crowdfunding-mobile-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/crowdfunding-mobile-app/crowdfunding-mobile-setup.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/crowdfunding-mobile-app/crowdfunding-mobile-con.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/crowdfunding-mobile-app/crowdfunding-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
